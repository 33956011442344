@import "styles/colors";
@import "styles/breakpoints";

.trendingStoriesContainer {
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  overflow-x: auto;
  @include tablet {
    padding: 0;
    overflow: hidden;
    justify-content: space-between;
    @include desktop {
      padding-left: 0;
      flex-direction: column;
      row-gap: 10px;
    }
  }

  .storyCard {
    padding: 0px;
    flex-shrink: 0;
    width: 48%;
    @include tablet {
      width: 32%;
      @include desktop {
        width: 100%;
        display: flex;
        flex-direction: row;
        column-gap: 25px;
      }
    }

    .thumbnail {
      width: 100%;
      @include desktop {
        width: 125px;
        height: 100%;
        object-fit: cover;
      }
    }

    .text {
      .categoryLine {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 1rem;
        text-transform: uppercase;
        color: $GREY_MEDIUM_LIGHT;

        @include desktop {
          display: revert;
          margin-top: 0;
          font-size: 1.2rem;
        }

        .sponsored {
          @include desktop {
            margin-top: 5px;
          }
        }
      }

      h2 {
        margin-top: 10px;
        font-weight: 400;
        font-size: 1.2rem;
        @include desktop {
          font-size: 1.6rem;
        }
      }

      .readMore {
        width: 110px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        color: $BRAND;
        font-weight: 700;
        text-transform: uppercase;
        transition: all .3s ease;

        @include desktop {
          margin-top: 10px;
        }

        .readMoreText {
          font-size: 1.2rem;
          @include desktop {
            font-size: 1.5rem;
          }
        }

        .arrowIcon {
          height: 10px;
          width: 100%;
          flex-grow: 1;
          margin-left: 10px;
        }
      }

      .readMore:hover {
        width: 115px;
      }
    }

    a:hover {
      text-decoration: none;
    }
  }
}
