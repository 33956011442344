@import "styles/colors";
@import "styles/breakpoints";

.signupWidget {
  background: $BRAND;
  padding: 50px;
  text-align: center;
  color: $WHITE;
  strong {
    font-family: Domaine;
    font-size: 1.9rem;
  }
  h2 {
    margin: 10px 0 20px;
    font-size: 2.4rem;
    @include desktop {
      font-size: 3.4rem;
    }
  }
  p {
    margin-top: 20px;
    font-size: 1.4rem;
  }
  .button {
    margin-top: 15px;
    min-width: 220px;
  }
}
