@import "styles/colors";
@import "styles/breakpoints";

.container {
  padding: 30px;
  background: $WHITE;
  @media only screen and (min-width: $DESKTOP) {
    box-shadow: none;
  }

  .thumbnail {
    width: 100%;
    vertical-align: bottom;
    margin-bottom: 20px;
  }

  .text {
    .sponsored {
      display: none;
      text-transform: uppercase;
      font-size: 1.2rem;
      color: $GREY_MEDIUM_LIGHT;
      margin-bottom: 20px;
      line-height: 1.2rem;
    }

    h2 {
      font-weight: 600;
      font-size: 2.4rem;
      @include desktop {
        font-size: 2.6rem;
      }
    }

    p {
      font-size: 1.2rem;
      line-height: 22.8px;
      @include desktop {
        font-size: 1.5rem;
      }
    }

    .readMore {
      width: 110px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;
      color: $BRAND;
      font-weight: 700;
      text-transform: uppercase;
      transition: all .3s ease;

      .readMoreText {
        font-size: 1.2rem;
        @include desktop {
          font-size: 1.5rem;
        }
      }

      .arrowIcon {
        height: 10px;
        width: 100%;
        flex-grow: 1;
        margin-left: 10px;
      }
    }

    .readMore:hover {
      width: 115px;
    }
  }

  a:hover {
    text-decoration: none;
  }
}

.container.sponsored {
  .sponsored {
    display: block;
  }
}

.container.hero {
  .innerContainer {
    @media only screen and (min-width: $DESKTOP) {
      max-width: 1200px;
      margin: auto;
      padding: 0 30px;
    }
  }

  .content {
    @media only screen and (min-width: $TABLET) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 60px;
      align-items: center;
    }

    .thumbnail {
      @include tablet {
        margin-bottom: 0px;
      }
    }
  }
}

.container.home.hero {
  padding: 0;
  margin-bottom: 30px;

  .innerContainer {
    @include desktop {
      padding: 0;
    }
  }

  .content {
    display: block;
  }

  .heroThumb {
    width: 100%;
    vertical-align: bottom;
    margin-bottom: 20px;
  }

  .text {
    padding: 0 30px;
    @include tablet {
      padding: 0;
    }
  }
}

.container.printHero {
  padding: 0;
  @include tablet {
    max-width: 100%;
    .content {
      display: flex;
      flex-direction: row;
    }
  }

  a {
    @include tablet {
      width: 50%;
      display: flex;
    }
  }

  .thumbnail {
    margin-bottom: 0;
    @include tablet {
      object-fit: cover;
    }
  }

  .category {
    display: none;
  }

  .readMore {
    display: none;
  }

  .text {
    background: $BRAND;
    color: $WHITE;
    padding: 30px;
    @include tablet {
      width: 50%;
      padding: 40px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      a {
        display: block;
        width: 100%;
      }
    }
    @include desktop {
      text-align: center;
      padding: 0 100px;
    }

    .lockContainer {
      display: flex;
      column-gap: 10px;
      align-items: baseline;
      @include desktop {
        justify-content: center;
      }

      .exclusiveContentLock {
        margin-bottom: 20px;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 600;
        @include desktop {
          font-size: 1.4rem;
        }
      }
    }

    .button {
      width: 100%;
      @include tablet {
        width: revert;
        padding: 15px 40px;
      }
      @include desktop {
        padding: 20px 45px;
        margin: 40px auto 0 auto;
      }
    }
  }
}
