@import "styles/colors";
@import "styles/breakpoints";

.newsletterSignupWidget {
  background: $BLACK;
  color: $WHITE;
  padding: 50px;
  text-align: center;

  h2 {
    font-size: 2.4rem;
    letter-spacing: 0.02em;
    @include desktop {
      font-size: 3.4rem;
    }
  }

  p {
    font-size: 1rem;
  }

  .loaderContainer {
    min-height: 51px;
    display: flex;
    align-items: center;
    margin-top: 30px;

    .loader {
      margin: 0 auto;
    }
  }

  .inputContainer,
  .successBox {
    position: relative;
    display: flex;
    align-items: center;
    margin: 30px auto 0 auto;
    @include tablet {
      max-width: 360px;
    }

    .input,
    .successMessage {
      width: 100%;
      font-size: 1rem;
      line-height: 15px;
      border: none;
      border-radius: 5px;
      padding: 18px 60px 18px 25px;
      background: rgba(255, 255, 255, 0.25);
      color: $WHITE;

      &::placeholder {
        color: $WHITE;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: $WHITE;
      transition: background-color 5000s ease-in-out 0s;
    }

    .submitButton {
      position: absolute;
      right: 20px;
      background: none;
      color: $WHITE;
      cursor: pointer;
      padding: 5px;
      .arrowIcon {
        width: 18px;
      }
    }

    .successIcon {
      position: absolute;
      right: 20px;
      width: 15px;
    }
  }

  .successBox {
    text-align: left;

    .successMessage {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}
