@import "styles/colors";
@import "styles/breakpoints";

.storyList {
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  row-gap: 20px;
  overflow-x: auto;
  @include tablet {
    display: grid;
    padding: 0;
    grid-template-columns: 1fr 1fr;
    @include desktop {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .storyCard {
    padding: 0px;
    flex-shrink: 0;
    width: 90%;
    @include tablet {
      width: revert;
    }

    .thumbnail {
      width: 100%;
    }

    .text {
      padding: 15px 0px;
      @include tablet {
        padding: 15px 0;
      }

      .categoryLine {
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
        text-transform: uppercase;
        color: $GREY_MEDIUM_LIGHT;
      }

      .headline {
        margin-top: 10px;
        font-family: Domaine Display Narrow Test, serif;
        font-weight: 600;
        font-size: 2.2rem;
        @include desktop {
          font-size: 2.6rem;
        }
      }

      .excerpt {
        margin-top: 15px;
        font-weight: 300;
        font-size: 1.2rem;
        @include desktop {
          font-size: 1.6rem;
        }
      }

      .readMore {
        width: 110px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 15px;
        color: $BRAND;
        font-weight: 700;
        text-transform: uppercase;
        transition: all .3s ease;

        .readMoreText {
          font-size: 1.2rem;
          @include desktop {
            font-size: 1.5rem;
          }
        }

        .arrowIcon {
          height: 10px;
          width: 100%;
          flex-grow: 1;
          margin-left: 10px;
        }
      }

      .readMore:hover {
        width: 115px;
      }
    }

    a:hover {
      text-decoration: none;
    }
  }
}
